import React, { useState } from 'react'
import { inject } from 'mobx-react'
import { Formik } from 'formik'
import styled from 'styled-components'
import { rem, flex, position } from 'styled-tidy'
import theme from 'lib/styles/theme'
import media from 'lib/styles/media'
import Title from 'components/title'
import Loader from 'components/loader'
import Paragraph from 'components/paragraph'
import Button from 'components/button'
import BounceLetters from 'components/bounce-letters'
import {
  Input,
  Error,
  RowSplit,
  RowSplitItem,
  SubmitButton,
} from 'components/forms/styles'
import leadBackground from 'images/lead-background.jpg'

const { ember, iron, white, tar, pink, green, orange } = theme.colors

const Wrap = styled.div`
  ${flex('column', 'center', 'center')}
  background: url(${leadBackground}) 50% 50% no-repeat ${ember};
  background-size: cover;
  overflow: hidden;
  padding: ${rem(32)} ${rem(16)} ${rem(64)};
  position: relative;
  width: 100%;
`

const Form = styled.form`
  max-width: 100%;
  width: 100% !important;

  h2 {
    border-color: ${iron};
    color: ${white};
    font-size: ${rem(24)};
  }

  > div {
    flex-wrap: wrap;

    > input,
    > button {
      max-width: 100%;
      width: 100% !important;
    }

    > div {
      width: 100% !important;
    }
  }

  button {
    border: ${rem(1)} solid ${iron};
    width: 100% !important;
  }

  ${media.xsmall`
    > div {
      > div {
        width: 50% !important;
      }
    }
  `}

  ${media.small`
    > div {
      flex-wrap: nowrap;

      > div {
        width: 35% !important;
      }

      button {
        border-left-width: 0;
        width: 30% !important;
      }
    }

    button {
      border-left-width: ${rem(1)};
    }
  `}

  ${media.medium`
    width: ${rem(576)} !important;
  `}
`

const Overlay = styled.div`
  ${flex('column', 'center', 'center')}
  ${position('absolute', 0)}
  background: rgba(30, 30, 30, 0.8);
  text-align: center;

  :before {
    ${position('absolute', 0)}
    background: radial-gradient(${tar}, transparent);
    content: '';
  }

  h5 {
    font-size: ${rem(24)};
    min-width: ${rem(160)};
  }

  p {
    color: ${white};
    font-size: ${rem(16)};
    font-weight: 200;
  }

  h5,
  p {
    border-color: ${iron};
    margin: 0 ${rem(16)};
    text-align: center;
    width: auto;
  }

  h5,
  p,
  > span {
    position: relative;
    z-index: 2;
  }
`

const LeadForm = ({ lead: leadStore }) => {
  const [view, setView] = useState('form')
  const [isBusy, setIsBusy] = useState(false)
  const { lead } = leadStore
  const { email = '', nameFirst = '', nameLast = '' } = lead
  const initialValues = { email, nameFirst, nameLast }

  const validate = ({ email, nameFirst, nameLast }) => {
    let errors = {}

    if (!email) {
      errors.email = 'Required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      errors.email = 'Invalid email address'
    }
    if (!nameFirst) errors.nameFirst = 'Required'
    if (!nameLast) errors.nameLast = 'Required'
    return errors
  }

  const submit = async ({ email, nameFirst, nameLast }) => {
    if (isBusy) return
    setIsBusy(true)
    const success = await leadStore.submitLead({ email, nameFirst, nameLast })
    setView(success ? 'done' : 'errored')
    setIsBusy(false)
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validate={validate}
      onSubmit={vals => submit(vals)}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <Wrap>
          <Form onSubmit={handleSubmit}>
            <Title is="h2">Signup for Deals</Title>
            <Input
              type="text"
              name="email"
              autoComplete="email"
              placeholder="Email address"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
            />
            {touched.email && errors.email && <Error>{errors.email}</Error>}
            <RowSplit split={[35, 35, 30]}>
              <RowSplitItem>
                <Input
                  type="text"
                  name="nameFirst"
                  autoComplete="given-name"
                  placeholder="First Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.nameFirst}
                />
                {touched.nameFirst && errors.nameFirst && (
                  <Error>{errors.nameFirst}</Error>
                )}
              </RowSplitItem>
              <RowSplitItem>
                <Input
                  type="text"
                  name="nameLast"
                  autoComplete="family-name"
                  placeholder="Last name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.nameLast}
                />
                {touched.nameLast && errors.nameLast && (
                  <Error>{errors.nameLast}</Error>
                )}
              </RowSplitItem>
              <SubmitButton disabled={isBusy} onMouseDown={handleSubmit}>
                Signup
              </SubmitButton>
            </RowSplit>
          </Form>
          {isBusy && (
            <Overlay>
              <Loader size={96} fill={pink} />
            </Overlay>
          )}
          {view === 'done' && (
            <Overlay>
              <Title is="h5" align="center" color={green}>
                <BounceLetters>Success</BounceLetters>
              </Title>
              <Paragraph>You're all signed up.</Paragraph>
            </Overlay>
          )}
          {view === 'errored' && (
            <Overlay>
              <Title is="h5" align="center" color={orange}>
                <BounceLetters>Uh oh</BounceLetters>
              </Title>
              <Paragraph>We were unable to get you signed up.</Paragraph>
              <Button size="slim" onMouseDown={() => setView('form')}>
                Okay
              </Button>
            </Overlay>
          )}
        </Wrap>
      )}
    />
  )
}

export default inject('lead')(LeadForm)
