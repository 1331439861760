import React, { useState } from 'react'
import { inject } from 'mobx-react'
import styled from 'styled-components'
import { is, rem, position, transparentize } from 'styled-tidy'
import theme from 'lib/styles/theme'
import media from 'lib/styles/media'
import Card from 'components/card'
import Button from 'components/button'
import ProductFigure from 'components/product-figure'
import ProductPricesToast from 'components/product-prices-toast'

let PRODUCT_CARD_TIMEOUT

const { colors, speeds } = theme
const { black } = colors

const Bottom = styled.div`
  width: 100%;

  span,
  button {
    display: block;
    width: 100%;
  }
`

const Mask = styled.div`
  ${position('fixed', 0)}
  animation: forwards ${speeds.slow} ease;
  background: ${transparentize(black, 0.2)};
  content: '';
  pointer-events: none;
  z-index: 4;

  ${media.small`
    background: ${transparentize(black, 0.7)};
    position: absolute;
    z-index: initial;
  `}
`

const Menu = styled.div`
  opacity: 0;
  pointer-events: none;
`

const Container = styled(Card)`
  position: static;

  ${media.small`
    overflow: hidden;
    position: relative;
  `}

  ${is('showPrices')`
    ${Menu} {
      opacity: 1;
      pointer-events: initial;
    }

    ${Mask} {
      animation: fadeIn forwards ${speeds.slow} ease;
      pointer-events: initial;
    }

    ${media.small`
      :before {
        opacity: 0.8;
        pointer-events: initial;
      }

      figure,
      ${Bottom} {
        filter: blur(${rem(1)});
      }
    `}
  `}
`

const ProductCard = ({ cart: cartStore, product }) => {
  const [showPrices, setShowPrices] = useState(false)
  const handleShowPrices = () => setShowPrices(true)
  const handleHidePrices = () => setShowPrices(false)

  const addToCart = sku => {
    if (PRODUCT_CARD_TIMEOUT) clearTimeout(PRODUCT_CARD_TIMEOUT)
    if (showPrices) handleHidePrices()
    cartStore.addItem(cartStore.createItemFromProductSku(product, sku))
  }

  const handleAddToCart = () => {
    if (product.prints) {
      if (product.prints.length === 1) {
        const print = product.prints[0]
        addToCart(print.sku, print.size)
      } else {
        handleShowPrices()
      }
    } else {
      addToCart(product.sku)
    }
  }

  return (
    <Container showPrices={showPrices}>
      <ProductFigure product={product} aspectRatio={1} />
      <Bottom>
        {product.isSold ? (
          <span>Sold Out</span>
        ) : (
          <Button onClick={handleAddToCart}>Add to Cart</Button>
        )}
      </Bottom>
      {showPrices && (
        <>
          <Mask onClick={handleHidePrices} />
          <Menu>
            <ProductPricesToast
              product={product}
              addToCart={addToCart}
              handleHide={handleHidePrices}
            />
          </Menu>
        </>
      )}
    </Container>
  )
}

export default inject('cart')(ProductCard)
