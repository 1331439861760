import React from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import { rem, value, position, is, over, between } from 'styled-tidy'
import theme from 'lib/styles/theme'
import stopEvent from 'lib/common/stop-event'
import ArtSize from 'components/art-size'

const { colors, fonts } = theme
const { pearl, gray, vapor } = colors

const Figure = styled.figure`
  border-bottom: ${rem(1)} solid ${pearl};
  margin: 0;
  padding: 0;
  width: 100%;

  figcaption {
    color: ${gray};
    font-weight: 200;
    line-height: 1.5;
    padding: ${rem(8)};
    text-align: center;
  }
`
const ImageLink = styled.a`
  background: linear-gradient(${vapor}, ${pearl});
  display: block;
  line-height: 0;
  overflow: hidden;
  position: relative;
  width: 100%;

  :after {
    content: '';
    display: block;
    padding-bottom: calc(${value('aspectRatio')} * 100%);
    width: 100%;
  }

  img {
    ${position('absolute', 0, 'auto', 'auto', 0)}
    height: 100%;
    width: 100%;

    ${between('aspectRatio', 0, 1)`
      object-fit: contain;
    `}

    ${is('aspectRatio', 1)`
      object-fit: cover;
    `}

    ${over('aspectRatio', 1)`
      object-fit: contain;
    `}
  }
`

const Name = styled.span`
  display: block;
  font-family: ${fonts.primary};
  font-size: ${rem(18)};

  :before {
    content: '"';
    padding: ${rem(2)};
  }

  :after {
    content: '"';
    padding: ${rem(2)};
  }
`

const ProductFigure = ({ product, aspectRatio, hideCaption }) => {
  const { name, size, slug, images } = product
  const imgRatio = aspectRatio || size.h / size.w
  const path = `/products/${slug}`

  const handleClick = event => {
    stopEvent(event)
    if (global.window) navigate(path)
  }

  return (
    <Figure>
      <ImageLink href={path} onClick={handleClick} aspectRatio={imgRatio}>
        <img src={images[0]} alt="" />
      </ImageLink>
      {!hideCaption && (
        <figcaption>
          <Name>{name}</Name>
          <ArtSize size={size} />
        </figcaption>
      )}
    </Figure>
  )
}

export default ProductFigure
