import React from 'react'
import Grid from 'components/grid'
import ProductCard from 'components/product-card'

const ProductGrid = ({ products, columns }) => (
  <Grid is="section" columns={columns}>
    {products.map(product => (
      <ProductCard key={`product-card=${product.sku}`} product={product} />
    ))}
  </Grid>
)

export default ProductGrid
