import React from 'react'
import styled from 'styled-components'
import { rem, position } from 'styled-tidy'
// import siteConfig from 'config/site'
import Layout from 'layouts/default'
import ProductGrid from 'components/product-grid'
import Wrap from 'components/wrap'
import LeadForm from 'components/forms/lead-form'
import Button from 'components/button'
import Callout from 'components/callout'
// import Countdown from 'components/countdown'
import { art } from 'lib/products'
import theme from 'lib/styles/theme'
import media from 'lib/styles/media'

const { silver } = theme.colors

const StyledWrap = styled(Wrap)`
  padding-bottom: ${rem(48)} !important;

  section > div:nth-child(n + 3) {
    display: none;

    ${media.small`
      display: block;
    `}
  }
`

const ButtonWrap = styled.div`
  position: relative;
  width: 100%;

  button {
    position: relative;
  }

  :before {
    ${position('absolute', '50%', 0, 'auto')};
    border-top: ${rem(1)} solid ${silver};
    content: '';
  }
`

const IndexPage = () => (
  <Layout path="/">
    <StyledWrap>
      <Callout small>
        Evilneon delivers stylized profile edits, acrylic on canvas originals
        and prints celebrating the harmony between dark and light.
      </Callout>
      <ProductGrid columns={3} products={art} />
      <ButtonWrap>
        <Button kind="secondary" size="slim" to="/shop">
          View All
        </Button>
      </ButtonWrap>
    </StyledWrap>
    {/* <Countdown date={siteConfig.launchDate}>
      <Title is="h2">{siteConfig.launchTitle}</Title>
    </Countdown> */}
    <LeadForm />
  </Layout>
)

export default IndexPage
