import React from 'react'
import styled from 'styled-components'
import { rem, value, flex } from 'styled-tidy'
import theme from 'lib/styles/theme'
import X from 'components/icons/x'

const { gray } = theme.colors

export const ArtSizeWrapper = styled.span`
  ${flex('row', 'center', 'center')}
  color: ${value('color')};
  font-size: ${rem(11)};
  font-weight: 600;
  padding: 0 ${rem(8)};
  white-space: nowrap;

  svg {
    margin: 0 ${rem(2)};
  }
`

const ArtSize = ({ size = {}, color = gray }) => (
  <ArtSizeWrapper color={color}>
    {Math.max(size.w, size.h)}
    <X size={8} fill={color} />
    {Math.min(size.w, size.h)}
  </ArtSizeWrapper>
)

export default ArtSize
