import React from 'react'
import styled from 'styled-components'
import { rem, over } from 'styled-tidy'
import media from 'lib/styles/media'

const Grid = ({ is, columns, children, ...rest }) => {
  const Element = styled(is || 'div')`
    display: grid;
    grid-gap: ${rem(16)};
    grid-template-columns: repeat(2, 1fr);
    padding: 0 0 ${rem(48)};
    width: 100%;

    ${over('columns', 2)`
      ${media.small`
        grid-gap: ${rem(24)};
        grid-template-columns: repeat(3, 1fr);
        padding-top: ${rem(8)};
      `}
    `}
    ${over('columns', 3)`
      ${media.medium`
        grid-gap: ${rem(32)};
        grid-template-columns: repeat(4, 1fr);
        padding-top: ${rem(16)};
      `}
    `}
    ${over('columns', 4)`
      ${media.large`
        grid-gap: ${rem(48)};
        grid-template-columns: repeat(5, 1fr);
        padding-top: ${rem(24)};
      `}
    `}
  `
  return <Element {...rest}>{children}</Element>
}

export default Grid
