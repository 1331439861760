import React from 'react'
import styled from 'styled-components'
import { rem, flex, position } from 'styled-tidy'
import theme from 'lib/styles/theme'
import media from 'lib/styles/media'
import X from 'components/icons/x'
import Button from 'components/button'
import ArtSize from 'components/art-size'
import Price from 'components/price'

const { colors, speeds } = theme
const { pearl, ember, black, pink } = colors

const List = styled.ol`
  ${position('fixed', 'auto', 0, 0)}
  padding: 0;
  animation: popUp ${speeds.quick}ms ease;
  width: 100%;
  z-index: 5;

  ${media.small`
    ${position('absolute', 0, 0, 'auto')}
    animation-name: popDown;
    overflow: hidden;
  `}
`

const Option = styled.li`
  border-top: ${rem(1)} solid ${pearl};
  list-style: none;
  padding: 0;
  position: relative;
  z-index: 2;

  :first-child {
    border-top-width: 0;
  }

  button {
    display: block;
    line-height: 1;
    min-height: ${rem(64)};
    width: 100%;

    &,
    :hover,
    :focus {
      padding: 0 ${rem(16)};
    }

    span {
      font-size: ${rem(15)};
      padding: 0;
    }
  }

  ${media.small`
    button {
      min-height: ${rem(48)};

      &,
      :hover,
      :focus {
        padding: 0 ${rem(12)};
      }

      span {
        font-size: ${rem(13)};
      }
    }
  `}
`

const OptionClose = styled(Option)`
  ${position('absolute', 'auto', 8, `calc(100% + ${rem(8)})`, 'auto')}
  border-top-width: 0;

  button {
    border-radius: ${rem(24)};
    min-height: ${rem(48)};
    width: ${rem(48)};

    span {
      ${flex('row', 'center', 'center')}
      font-size: ${rem(32)};
      height: ${rem(48)};
      text-align: center;
      width: 100%;
    }
  }

  ${media.small`
    border-top-width: ${rem(1)};
    box-shadow: 0 0 ${rem(16)} ${black};
    position: static;
    z-index: 1;

    button {
      border-radius: 0;
      text-align: right;
      width: 100%;

      span {
        display: inline;
        font-size: ${rem(24)};
      }
    }
  `}
`

const Details = styled.span`
  ${flex('row-reverse', 'center', 'space-between')}
  min-height: ${rem(64)};

  span {
    background: none;

    :first-child {
      ${flex('row', 'center', 'flex-start')}
      border: solid ${pearl};
      border-width: 0 0 0 ${rem(1)};
      margin: 0 0 0 ${rem(16)};
      min-height: ${rem(64)};
      padding: 0 0 0 ${rem(16)};
      flex-grow: 1;
      text-align: left;
    }

    + span {
      justify-content: flex-end;
      min-width: ${rem(96)};
      text-align: right;
    }
  }

  ${media.small`
    flex-direction: row;
    justify-content: space-between;

    span:first-child {
      border-width: 0;
      margin: 0;
      padding: 0;
    }
  `}
`

const ProductPricesToast = ({ product, handleHide, addToCart }) => (
  <List>
    {product.prints.map(({ sku, size, price }) => (
      <Option key={`product-toast-option-${sku}`}>
        <Button onClick={() => addToCart(sku)}>
          <Details>
            <ArtSize size={size} color={ember} />
            <Price cents={price} />
          </Details>
        </Button>
      </Option>
    ))}
    {handleHide && (
      <OptionClose>
        <Button aria-label="Close price menu" onClick={() => handleHide()}>
          <X fill={pink} />
        </Button>
      </OptionClose>
    )}
  </List>
)

export default ProductPricesToast
