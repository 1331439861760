// Auto-generated file
export const products = [

  {
    type: 'edit',
    sku: 'prod_DUVbih0hSTQ2HS',
    name: 'Digital Portrait',
    slug: 'digital-portrait',
    description: '',
    packages: [
    
      {
        sku: 'sku_DUVeZ58MI0JW6l',
        price: 5000,
      },
    
    ],
  },
  {
    type: 'art',
    sku: 'sku_DEWSjvoSEbQQMB',
    name: 'Brilliant',
    slug: 'brilliant',
    images: ['https://res.cloudinary.com/du0k1zjnk/image/upload/v1531634816/products/brilliant.jpg'],
    size: {
      w: 24,
      h: 18,
    },
    price: 60000,
    isSold: false,
    description: '',
    prints: [
    
      {
        sku: 'sku_DEWTfo8eyLBp6U',
        size: {
          w: 12,
          h: 18,
        },
        price: 1500,
      },
    
      {
        sku: 'sku_DEWTtAS5zdYMck',
        size: {
          w: 24,
          h: 18,
        },
        price: 3000,
      },
    
    ],
  },
  {
    type: 'art',
    sku: 'sku_DEVEgg6TKro0kt',
    name: 'Magmus',
    slug: 'magmus',
    images: ['https://res.cloudinary.com/du0k1zjnk/image/upload/v1531632097/products/prod_magmus.jpg'],
    size: {
      w: 18,
      h: 24,
    },
    price: 45000,
    isSold: false,
    description: '',
    prints: [
    
      {
        sku: 'sku_DEVFL6PC6UEgmg',
        size: {
          w: 18,
          h: 24,
        },
        price: 3000,
      },
    
    ],
  },
  {
    type: 'art',
    sku: 'sku_DCCgRoZgFrL9MP',
    name: 'Ephemeral',
    slug: 'ephemeral',
    images: ['https://res.cloudinary.com/du0k1zjnk/image/upload/v1530835221/products/prod_ephemeral.jpg'],
    size: {
      w: 24,
      h: 24,
    },
    price: 75000,
    isSold: false,
    description: '',
    prints: [
    
      {
        sku: 'sku_DCCjrRpetjFRlA',
        size: {
          w: 24,
          h: 24,
        },
        price: 4500,
      },
    
      {
        sku: 'sku_DCCirGSiskWsIF',
        size: {
          w: 18,
          h: 18,
        },
        price: 2500,
      },
    
      {
        sku: 'sku_DCChibBYwQouYY',
        size: {
          w: 12,
          h: 12,
        },
        price: 1500,
      },
    
    ],
  },
]

export const art = products.filter(product => product.type === 'art')
export const digitalEdit = products.find(product => product.type === 'edit')
export default products
